<template>
  <div class="page">
    <div>
        <el-row :gutter="30">
            <el-col :span="6">
                <div class="title">关于我们</div>
                <div class="info ti">浙江共生店信息科技有限公司，坐落于风景秀丽、经济蓬勃发展的浙江省，是一家专注于健康咨询服务（非医疗）领域的创新型企业。自成立以来，我们始终秉持“以人为本，健康为先”的服务理念，致力于为广大客户提供专业、全面、贴心的健康咨询解决方案。</div>
            </el-col>
            <el-col :span="6">
                <div class="title">快捷导航</div>
                <div>
                    <el-row :gutter="50">
                        <el-col :span="12">
                            <div class="info l50">关于我们</div>
                        </el-col>
                        <el-col :span="12">
                            <div class="info  l50">产品中心</div>
                        </el-col>
                        <el-col :span="12">
                            <div class="info  l50">新闻中心</div>
                        </el-col>
                        <el-col :span="12">
                            <div class="info  l50">联系我们</div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="title">联系方式</div>
                <div class="info l50">联系电话：13121648382</div>
                <div class="info l50">邮箱：171620024@qq.com</div>
                <div class="info l50">联系地址：浙江省杭州市西湖区转塘街道桐坞村傅家83号401室</div>
            </el-col>
            <el-col :span="6">
                <div class="title">友情联接</div>
            </el-col>
        </el-row>
    </div>
    <div>
        <div class="info ba">
            Copyright © 2022 浙江共生店信息科技有限公司  苏ICP备2022011148号-5    
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    created(){

    },
    methods:{

    }
}
</script>

<style scoped>
    .page{
        max-width:1280px;
        margin:0 auto
    }
    .title{
        color:#fff;
        line-height:40px;
        margin-top:30px;
        font-weight: bolder;
        border-bottom:1px solid #303330
    }
    .info{
        color:#fff;
        font-size:14px;
    }
    .ti{
        text-indent: 2em;
    }
    .l50{
        line-height:30px;
    }
    .info:hover{
        cursor: pointer;
    }
    .ba{
        margin-top:30px;
        line-height:60px
    }
</style>